header {
    min-height: 20px;
    padding-top: 18px;
    padding-bottom: 10px;
}

header .fa {
    color: #28a7e9;
}

header span {
    font-weight: bold;
    padding-right: 10px;
    padding-left: 4px;
}

header a {
    color: #999;
    font-weight: 600;
}

header .social-icon {
    text-align: right;
}

header .social-icon li a {
    border: none;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    display: inline-block;
}

