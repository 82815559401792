#successMessage {
    position: relative;
    height: auto;
    min-height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

    #successMessage h3 {
        vertical-align: middle;
        margin-bottom: 20px;
    }
