a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html { font-size: 14px; }

@media (min-width: 768px) {
    html { font-size: 16px; }
}

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }

.navbar {
    padding: 0 32px 0 0;
}

.navbar-default {
    background: #fff;
    border: none;
    box-shadow: none;
    margin: 0 !important;
}

.navbar-default .navbar-brand {
    font-weight: 500;
    font-size: 30px;
    height: 70px;
    line-height: 40px;
    padding: 12px 0px 0px 12px;
}

.navbar li a {
    font-weight: bold;
    font-size: 13px;
    height: 70px;
    line-height: 40px;
    padding: 15px !important;
}

.navbar-nav > li > a.current {
    color: #28A7E9 !important;
}

.templatemo-nav {
    border-radius: 0;
    width: 100%;
    z-index: 1000;
}

@media only screen and ( max-width: 767px ) {
    .templatemo-nav {
        position: fixed;
        top: 0;
    }

    
}